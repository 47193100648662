
    .itravel_tag_icon {
        background: center/contain no-repeat;
        width: 100px;
        height: 100px;
    }
.itravel_tag_icon.action_sports { background-image: url('icons/action_sports.svg')}
.itravel_tag_icon.adult_only_trips { background-image: url('icons/adult_only_trips.svg')}
.itravel_tag_icon.air_activities { background-image: url('icons/air_activities.svg')}
.itravel_tag_icon.airplane_hotel { background-image: url('icons/airplane_hotel.svg')}
.itravel_tag_icon.animals { background-image: url('icons/animals.svg')}
.itravel_tag_icon.art_exhibition_museum { background-image: url('icons/art_exhibition_museum.svg')}
.itravel_tag_icon.ball_sports1 { background-image: url('icons/ball_sports1.svg')}
.itravel_tag_icon.ball_sports2 { background-image: url('icons/ball_sports2.svg')}
.itravel_tag_icon.bicycling { background-image: url('icons/bicycling.svg')}
.itravel_tag_icon.boutique_hotels { background-image: url('icons/boutique_hotels.svg')}
.itravel_tag_icon.butler { background-image: url('icons/butler.svg')}
.itravel_tag_icon.canopy_sleeping { background-image: url('icons/canopy_sleeping.svg')}
.itravel_tag_icon.climbing { background-image: url('icons/climbing.svg')}
.itravel_tag_icon.conscious_travel1 { background-image: url('icons/conscious_travel1.svg')}
.itravel_tag_icon.conscious_travel2 { background-image: url('icons/conscious_travel2.svg')}
.itravel_tag_icon.cruises { background-image: url('icons/cruises.svg')}
.itravel_tag_icon.culture_trips { background-image: url('icons/culture_trips.svg')}
.itravel_tag_icon.default { background-image: url('icons/default.svg')}
.itravel_tag_icon.desert_tent { background-image: url('icons/desert_tent.svg')}
.itravel_tag_icon.discovery_trips { background-image: url('icons/discovery_trips.svg')}
.itravel_tag_icon.diving { background-image: url('icons/diving.svg')}
.itravel_tag_icon.eat_and_drink { background-image: url('icons/eat_and_drink.svg')}
.itravel_tag_icon.economy_lodges { background-image: url('icons/economy_lodges.svg')}
.itravel_tag_icon.events { background-image: url('icons/events.svg')}
.itravel_tag_icon.family_safari { background-image: url('icons/family_safari.svg')}
.itravel_tag_icon.family_trips { background-image: url('icons/family_trips.svg')}
.itravel_tag_icon.fishing { background-image: url('icons/fishing.svg')}
.itravel_tag_icon.fitness_and_yoga { background-image: url('icons/fitness_and_yoga.svg')}
.itravel_tag_icon.flag_ad { background-image: url('icons/flag_ad.svg')}
.itravel_tag_icon.flag_ae { background-image: url('icons/flag_ae.svg')}
.itravel_tag_icon.flag_af { background-image: url('icons/flag_af.svg')}
.itravel_tag_icon.flag_ag { background-image: url('icons/flag_ag.svg')}
.itravel_tag_icon.flag_ai { background-image: url('icons/flag_ai.svg')}
.itravel_tag_icon.flag_al { background-image: url('icons/flag_al.svg')}
.itravel_tag_icon.flag_am { background-image: url('icons/flag_am.svg')}
.itravel_tag_icon.flag_ao { background-image: url('icons/flag_ao.svg')}
.itravel_tag_icon.flag_aq { background-image: url('icons/flag_aq.svg')}
.itravel_tag_icon.flag_ar { background-image: url('icons/flag_ar.svg')}
.itravel_tag_icon.flag_as { background-image: url('icons/flag_as.svg')}
.itravel_tag_icon.flag_at { background-image: url('icons/flag_at.svg')}
.itravel_tag_icon.flag_au { background-image: url('icons/flag_au.svg')}
.itravel_tag_icon.flag_aw { background-image: url('icons/flag_aw.svg')}
.itravel_tag_icon.flag_ax { background-image: url('icons/flag_ax.svg')}
.itravel_tag_icon.flag_az { background-image: url('icons/flag_az.svg')}
.itravel_tag_icon.flag_ba { background-image: url('icons/flag_ba.svg')}
.itravel_tag_icon.flag_bb { background-image: url('icons/flag_bb.svg')}
.itravel_tag_icon.flag_bd { background-image: url('icons/flag_bd.svg')}
.itravel_tag_icon.flag_be { background-image: url('icons/flag_be.svg')}
.itravel_tag_icon.flag_bf { background-image: url('icons/flag_bf.svg')}
.itravel_tag_icon.flag_bg { background-image: url('icons/flag_bg.svg')}
.itravel_tag_icon.flag_bh { background-image: url('icons/flag_bh.svg')}
.itravel_tag_icon.flag_bi { background-image: url('icons/flag_bi.svg')}
.itravel_tag_icon.flag_bj { background-image: url('icons/flag_bj.svg')}
.itravel_tag_icon.flag_bl { background-image: url('icons/flag_bl.svg')}
.itravel_tag_icon.flag_bm { background-image: url('icons/flag_bm.svg')}
.itravel_tag_icon.flag_bn { background-image: url('icons/flag_bn.svg')}
.itravel_tag_icon.flag_bo { background-image: url('icons/flag_bo.svg')}
.itravel_tag_icon.flag_bq { background-image: url('icons/flag_bq.svg')}
.itravel_tag_icon.flag_br { background-image: url('icons/flag_br.svg')}
.itravel_tag_icon.flag_bs { background-image: url('icons/flag_bs.svg')}
.itravel_tag_icon.flag_bt { background-image: url('icons/flag_bt.svg')}
.itravel_tag_icon.flag_bv { background-image: url('icons/flag_bv.svg')}
.itravel_tag_icon.flag_bw { background-image: url('icons/flag_bw.svg')}
.itravel_tag_icon.flag_by { background-image: url('icons/flag_by.svg')}
.itravel_tag_icon.flag_bz { background-image: url('icons/flag_bz.svg')}
.itravel_tag_icon.flag_ca { background-image: url('icons/flag_ca.svg')}
.itravel_tag_icon.flag_cc { background-image: url('icons/flag_cc.svg')}
.itravel_tag_icon.flag_cd { background-image: url('icons/flag_cd.svg')}
.itravel_tag_icon.flag_cf { background-image: url('icons/flag_cf.svg')}
.itravel_tag_icon.flag_cg { background-image: url('icons/flag_cg.svg')}
.itravel_tag_icon.flag_ch { background-image: url('icons/flag_ch.svg')}
.itravel_tag_icon.flag_ci { background-image: url('icons/flag_ci.svg')}
.itravel_tag_icon.flag_ck { background-image: url('icons/flag_ck.svg')}
.itravel_tag_icon.flag_cl { background-image: url('icons/flag_cl.svg')}
.itravel_tag_icon.flag_cm { background-image: url('icons/flag_cm.svg')}
.itravel_tag_icon.flag_cn { background-image: url('icons/flag_cn.svg')}
.itravel_tag_icon.flag_co { background-image: url('icons/flag_co.svg')}
.itravel_tag_icon.flag_cr { background-image: url('icons/flag_cr.svg')}
.itravel_tag_icon.flag_cu { background-image: url('icons/flag_cu.svg')}
.itravel_tag_icon.flag_cv { background-image: url('icons/flag_cv.svg')}
.itravel_tag_icon.flag_cw { background-image: url('icons/flag_cw.svg')}
.itravel_tag_icon.flag_cx { background-image: url('icons/flag_cx.svg')}
.itravel_tag_icon.flag_cy { background-image: url('icons/flag_cy.svg')}
.itravel_tag_icon.flag_cz { background-image: url('icons/flag_cz.svg')}
.itravel_tag_icon.flag_de { background-image: url('icons/flag_de.svg')}
.itravel_tag_icon.flag_dj { background-image: url('icons/flag_dj.svg')}
.itravel_tag_icon.flag_dk { background-image: url('icons/flag_dk.svg')}
.itravel_tag_icon.flag_dm { background-image: url('icons/flag_dm.svg')}
.itravel_tag_icon.flag_do { background-image: url('icons/flag_do.svg')}
.itravel_tag_icon.flag_dz { background-image: url('icons/flag_dz.svg')}
.itravel_tag_icon.flag_ec { background-image: url('icons/flag_ec.svg')}
.itravel_tag_icon.flag_ee { background-image: url('icons/flag_ee.svg')}
.itravel_tag_icon.flag_eg { background-image: url('icons/flag_eg.svg')}
.itravel_tag_icon.flag_eh { background-image: url('icons/flag_eh.svg')}
.itravel_tag_icon.flag_er { background-image: url('icons/flag_er.svg')}
.itravel_tag_icon.flag_es { background-image: url('icons/flag_es.svg')}
.itravel_tag_icon.flag_et { background-image: url('icons/flag_et.svg')}
.itravel_tag_icon.flag_eu { background-image: url('icons/flag_eu.svg')}
.itravel_tag_icon.flag_fi { background-image: url('icons/flag_fi.svg')}
.itravel_tag_icon.flag_fj { background-image: url('icons/flag_fj.svg')}
.itravel_tag_icon.flag_fk { background-image: url('icons/flag_fk.svg')}
.itravel_tag_icon.flag_fm { background-image: url('icons/flag_fm.svg')}
.itravel_tag_icon.flag_fo { background-image: url('icons/flag_fo.svg')}
.itravel_tag_icon.flag_fr { background-image: url('icons/flag_fr.svg')}
.itravel_tag_icon.flag_ga { background-image: url('icons/flag_ga.svg')}
.itravel_tag_icon.flag_gb-eng { background-image: url('icons/flag_gb-eng.svg')}
.itravel_tag_icon.flag_gb-nir { background-image: url('icons/flag_gb-nir.svg')}
.itravel_tag_icon.flag_gb-sct { background-image: url('icons/flag_gb-sct.svg')}
.itravel_tag_icon.flag_gb-wls { background-image: url('icons/flag_gb-wls.svg')}
.itravel_tag_icon.flag_gb { background-image: url('icons/flag_gb.svg')}
.itravel_tag_icon.flag_gd { background-image: url('icons/flag_gd.svg')}
.itravel_tag_icon.flag_ge { background-image: url('icons/flag_ge.svg')}
.itravel_tag_icon.flag_gf { background-image: url('icons/flag_gf.svg')}
.itravel_tag_icon.flag_gg { background-image: url('icons/flag_gg.svg')}
.itravel_tag_icon.flag_gh { background-image: url('icons/flag_gh.svg')}
.itravel_tag_icon.flag_gi { background-image: url('icons/flag_gi.svg')}
.itravel_tag_icon.flag_gl { background-image: url('icons/flag_gl.svg')}
.itravel_tag_icon.flag_gm { background-image: url('icons/flag_gm.svg')}
.itravel_tag_icon.flag_gn { background-image: url('icons/flag_gn.svg')}
.itravel_tag_icon.flag_gp { background-image: url('icons/flag_gp.svg')}
.itravel_tag_icon.flag_gq { background-image: url('icons/flag_gq.svg')}
.itravel_tag_icon.flag_gr { background-image: url('icons/flag_gr.svg')}
.itravel_tag_icon.flag_gs { background-image: url('icons/flag_gs.svg')}
.itravel_tag_icon.flag_gt { background-image: url('icons/flag_gt.svg')}
.itravel_tag_icon.flag_gu { background-image: url('icons/flag_gu.svg')}
.itravel_tag_icon.flag_gw { background-image: url('icons/flag_gw.svg')}
.itravel_tag_icon.flag_gy { background-image: url('icons/flag_gy.svg')}
.itravel_tag_icon.flag_hk { background-image: url('icons/flag_hk.svg')}
.itravel_tag_icon.flag_hm { background-image: url('icons/flag_hm.svg')}
.itravel_tag_icon.flag_hn { background-image: url('icons/flag_hn.svg')}
.itravel_tag_icon.flag_hr { background-image: url('icons/flag_hr.svg')}
.itravel_tag_icon.flag_ht { background-image: url('icons/flag_ht.svg')}
.itravel_tag_icon.flag_hu { background-image: url('icons/flag_hu.svg')}
.itravel_tag_icon.flag_id { background-image: url('icons/flag_id.svg')}
.itravel_tag_icon.flag_ie { background-image: url('icons/flag_ie.svg')}
.itravel_tag_icon.flag_il { background-image: url('icons/flag_il.svg')}
.itravel_tag_icon.flag_im { background-image: url('icons/flag_im.svg')}
.itravel_tag_icon.flag_in { background-image: url('icons/flag_in.svg')}
.itravel_tag_icon.flag_io { background-image: url('icons/flag_io.svg')}
.itravel_tag_icon.flag_iq { background-image: url('icons/flag_iq.svg')}
.itravel_tag_icon.flag_ir { background-image: url('icons/flag_ir.svg')}
.itravel_tag_icon.flag_is { background-image: url('icons/flag_is.svg')}
.itravel_tag_icon.flag_it { background-image: url('icons/flag_it.svg')}
.itravel_tag_icon.flag_je { background-image: url('icons/flag_je.svg')}
.itravel_tag_icon.flag_jm { background-image: url('icons/flag_jm.svg')}
.itravel_tag_icon.flag_jo { background-image: url('icons/flag_jo.svg')}
.itravel_tag_icon.flag_jp { background-image: url('icons/flag_jp.svg')}
.itravel_tag_icon.flag_ke { background-image: url('icons/flag_ke.svg')}
.itravel_tag_icon.flag_kg { background-image: url('icons/flag_kg.svg')}
.itravel_tag_icon.flag_kh { background-image: url('icons/flag_kh.svg')}
.itravel_tag_icon.flag_ki { background-image: url('icons/flag_ki.svg')}
.itravel_tag_icon.flag_km { background-image: url('icons/flag_km.svg')}
.itravel_tag_icon.flag_kn { background-image: url('icons/flag_kn.svg')}
.itravel_tag_icon.flag_kp { background-image: url('icons/flag_kp.svg')}
.itravel_tag_icon.flag_kr { background-image: url('icons/flag_kr.svg')}
.itravel_tag_icon.flag_kw { background-image: url('icons/flag_kw.svg')}
.itravel_tag_icon.flag_ky { background-image: url('icons/flag_ky.svg')}
.itravel_tag_icon.flag_kz { background-image: url('icons/flag_kz.svg')}
.itravel_tag_icon.flag_la { background-image: url('icons/flag_la.svg')}
.itravel_tag_icon.flag_lb { background-image: url('icons/flag_lb.svg')}
.itravel_tag_icon.flag_lc { background-image: url('icons/flag_lc.svg')}
.itravel_tag_icon.flag_li { background-image: url('icons/flag_li.svg')}
.itravel_tag_icon.flag_lk { background-image: url('icons/flag_lk.svg')}
.itravel_tag_icon.flag_lr { background-image: url('icons/flag_lr.svg')}
.itravel_tag_icon.flag_ls { background-image: url('icons/flag_ls.svg')}
.itravel_tag_icon.flag_lt { background-image: url('icons/flag_lt.svg')}
.itravel_tag_icon.flag_lu { background-image: url('icons/flag_lu.svg')}
.itravel_tag_icon.flag_lv { background-image: url('icons/flag_lv.svg')}
.itravel_tag_icon.flag_ly { background-image: url('icons/flag_ly.svg')}
.itravel_tag_icon.flag_ma { background-image: url('icons/flag_ma.svg')}
.itravel_tag_icon.flag_mc { background-image: url('icons/flag_mc.svg')}
.itravel_tag_icon.flag_md { background-image: url('icons/flag_md.svg')}
.itravel_tag_icon.flag_me { background-image: url('icons/flag_me.svg')}
.itravel_tag_icon.flag_mf { background-image: url('icons/flag_mf.svg')}
.itravel_tag_icon.flag_mg { background-image: url('icons/flag_mg.svg')}
.itravel_tag_icon.flag_mh { background-image: url('icons/flag_mh.svg')}
.itravel_tag_icon.flag_mk { background-image: url('icons/flag_mk.svg')}
.itravel_tag_icon.flag_ml { background-image: url('icons/flag_ml.svg')}
.itravel_tag_icon.flag_mm { background-image: url('icons/flag_mm.svg')}
.itravel_tag_icon.flag_mn { background-image: url('icons/flag_mn.svg')}
.itravel_tag_icon.flag_mo { background-image: url('icons/flag_mo.svg')}
.itravel_tag_icon.flag_mp { background-image: url('icons/flag_mp.svg')}
.itravel_tag_icon.flag_mq { background-image: url('icons/flag_mq.svg')}
.itravel_tag_icon.flag_mr { background-image: url('icons/flag_mr.svg')}
.itravel_tag_icon.flag_ms { background-image: url('icons/flag_ms.svg')}
.itravel_tag_icon.flag_mt { background-image: url('icons/flag_mt.svg')}
.itravel_tag_icon.flag_mu { background-image: url('icons/flag_mu.svg')}
.itravel_tag_icon.flag_mv { background-image: url('icons/flag_mv.svg')}
.itravel_tag_icon.flag_mw { background-image: url('icons/flag_mw.svg')}
.itravel_tag_icon.flag_mx { background-image: url('icons/flag_mx.svg')}
.itravel_tag_icon.flag_my { background-image: url('icons/flag_my.svg')}
.itravel_tag_icon.flag_mz { background-image: url('icons/flag_mz.svg')}
.itravel_tag_icon.flag_na { background-image: url('icons/flag_na.svg')}
.itravel_tag_icon.flag_nc { background-image: url('icons/flag_nc.svg')}
.itravel_tag_icon.flag_ne { background-image: url('icons/flag_ne.svg')}
.itravel_tag_icon.flag_nf { background-image: url('icons/flag_nf.svg')}
.itravel_tag_icon.flag_ng { background-image: url('icons/flag_ng.svg')}
.itravel_tag_icon.flag_ni { background-image: url('icons/flag_ni.svg')}
.itravel_tag_icon.flag_nl { background-image: url('icons/flag_nl.svg')}
.itravel_tag_icon.flag_no { background-image: url('icons/flag_no.svg')}
.itravel_tag_icon.flag_np { background-image: url('icons/flag_np.svg')}
.itravel_tag_icon.flag_nr { background-image: url('icons/flag_nr.svg')}
.itravel_tag_icon.flag_nu { background-image: url('icons/flag_nu.svg')}
.itravel_tag_icon.flag_nz { background-image: url('icons/flag_nz.svg')}
.itravel_tag_icon.flag_om { background-image: url('icons/flag_om.svg')}
.itravel_tag_icon.flag_pa { background-image: url('icons/flag_pa.svg')}
.itravel_tag_icon.flag_pe { background-image: url('icons/flag_pe.svg')}
.itravel_tag_icon.flag_pf { background-image: url('icons/flag_pf.svg')}
.itravel_tag_icon.flag_pg { background-image: url('icons/flag_pg.svg')}
.itravel_tag_icon.flag_ph { background-image: url('icons/flag_ph.svg')}
.itravel_tag_icon.flag_pk { background-image: url('icons/flag_pk.svg')}
.itravel_tag_icon.flag_pl { background-image: url('icons/flag_pl.svg')}
.itravel_tag_icon.flag_pm { background-image: url('icons/flag_pm.svg')}
.itravel_tag_icon.flag_pn { background-image: url('icons/flag_pn.svg')}
.itravel_tag_icon.flag_pr { background-image: url('icons/flag_pr.svg')}
.itravel_tag_icon.flag_ps { background-image: url('icons/flag_ps.svg')}
.itravel_tag_icon.flag_pt { background-image: url('icons/flag_pt.svg')}
.itravel_tag_icon.flag_pw { background-image: url('icons/flag_pw.svg')}
.itravel_tag_icon.flag_py { background-image: url('icons/flag_py.svg')}
.itravel_tag_icon.flag_qa { background-image: url('icons/flag_qa.svg')}
.itravel_tag_icon.flag_re { background-image: url('icons/flag_re.svg')}
.itravel_tag_icon.flag_ro { background-image: url('icons/flag_ro.svg')}
.itravel_tag_icon.flag_rs { background-image: url('icons/flag_rs.svg')}
.itravel_tag_icon.flag_ru { background-image: url('icons/flag_ru.svg')}
.itravel_tag_icon.flag_rw { background-image: url('icons/flag_rw.svg')}
.itravel_tag_icon.flag_sa { background-image: url('icons/flag_sa.svg')}
.itravel_tag_icon.flag_sb { background-image: url('icons/flag_sb.svg')}
.itravel_tag_icon.flag_sc { background-image: url('icons/flag_sc.svg')}
.itravel_tag_icon.flag_sd { background-image: url('icons/flag_sd.svg')}
.itravel_tag_icon.flag_se { background-image: url('icons/flag_se.svg')}
.itravel_tag_icon.flag_sg { background-image: url('icons/flag_sg.svg')}
.itravel_tag_icon.flag_sh { background-image: url('icons/flag_sh.svg')}
.itravel_tag_icon.flag_si { background-image: url('icons/flag_si.svg')}
.itravel_tag_icon.flag_sj { background-image: url('icons/flag_sj.svg')}
.itravel_tag_icon.flag_sk { background-image: url('icons/flag_sk.svg')}
.itravel_tag_icon.flag_sl { background-image: url('icons/flag_sl.svg')}
.itravel_tag_icon.flag_sm { background-image: url('icons/flag_sm.svg')}
.itravel_tag_icon.flag_sn { background-image: url('icons/flag_sn.svg')}
.itravel_tag_icon.flag_so { background-image: url('icons/flag_so.svg')}
.itravel_tag_icon.flag_sr { background-image: url('icons/flag_sr.svg')}
.itravel_tag_icon.flag_ss { background-image: url('icons/flag_ss.svg')}
.itravel_tag_icon.flag_st { background-image: url('icons/flag_st.svg')}
.itravel_tag_icon.flag_sv { background-image: url('icons/flag_sv.svg')}
.itravel_tag_icon.flag_sx { background-image: url('icons/flag_sx.svg')}
.itravel_tag_icon.flag_sy { background-image: url('icons/flag_sy.svg')}
.itravel_tag_icon.flag_sz { background-image: url('icons/flag_sz.svg')}
.itravel_tag_icon.flag_tc { background-image: url('icons/flag_tc.svg')}
.itravel_tag_icon.flag_td { background-image: url('icons/flag_td.svg')}
.itravel_tag_icon.flag_tf { background-image: url('icons/flag_tf.svg')}
.itravel_tag_icon.flag_tg { background-image: url('icons/flag_tg.svg')}
.itravel_tag_icon.flag_th { background-image: url('icons/flag_th.svg')}
.itravel_tag_icon.flag_tj { background-image: url('icons/flag_tj.svg')}
.itravel_tag_icon.flag_tk { background-image: url('icons/flag_tk.svg')}
.itravel_tag_icon.flag_tl { background-image: url('icons/flag_tl.svg')}
.itravel_tag_icon.flag_tm { background-image: url('icons/flag_tm.svg')}
.itravel_tag_icon.flag_tn { background-image: url('icons/flag_tn.svg')}
.itravel_tag_icon.flag_to { background-image: url('icons/flag_to.svg')}
.itravel_tag_icon.flag_tr { background-image: url('icons/flag_tr.svg')}
.itravel_tag_icon.flag_tt { background-image: url('icons/flag_tt.svg')}
.itravel_tag_icon.flag_tv { background-image: url('icons/flag_tv.svg')}
.itravel_tag_icon.flag_tw { background-image: url('icons/flag_tw.svg')}
.itravel_tag_icon.flag_tz { background-image: url('icons/flag_tz.svg')}
.itravel_tag_icon.flag_ua { background-image: url('icons/flag_ua.svg')}
.itravel_tag_icon.flag_ug { background-image: url('icons/flag_ug.svg')}
.itravel_tag_icon.flag_um { background-image: url('icons/flag_um.svg')}
.itravel_tag_icon.flag_un { background-image: url('icons/flag_un.svg')}
.itravel_tag_icon.flag_us { background-image: url('icons/flag_us.svg')}
.itravel_tag_icon.flag_uy { background-image: url('icons/flag_uy.svg')}
.itravel_tag_icon.flag_uz { background-image: url('icons/flag_uz.svg')}
.itravel_tag_icon.flag_va { background-image: url('icons/flag_va.svg')}
.itravel_tag_icon.flag_vc { background-image: url('icons/flag_vc.svg')}
.itravel_tag_icon.flag_ve { background-image: url('icons/flag_ve.svg')}
.itravel_tag_icon.flag_vg { background-image: url('icons/flag_vg.svg')}
.itravel_tag_icon.flag_vi { background-image: url('icons/flag_vi.svg')}
.itravel_tag_icon.flag_vn { background-image: url('icons/flag_vn.svg')}
.itravel_tag_icon.flag_vu { background-image: url('icons/flag_vu.svg')}
.itravel_tag_icon.flag_wf { background-image: url('icons/flag_wf.svg')}
.itravel_tag_icon.flag_ws { background-image: url('icons/flag_ws.svg')}
.itravel_tag_icon.flag_ye { background-image: url('icons/flag_ye.svg')}
.itravel_tag_icon.flag_yt { background-image: url('icons/flag_yt.svg')}
.itravel_tag_icon.flag_za { background-image: url('icons/flag_za.svg')}
.itravel_tag_icon.flag_zm { background-image: url('icons/flag_zm.svg')}
.itravel_tag_icon.flag_zw { background-image: url('icons/flag_zw.svg')}
.itravel_tag_icon.gay_trips { background-image: url('icons/gay_trips.svg')}
.itravel_tag_icon.glamorous_camping { background-image: url('icons/glamorous_camping.svg')}
.itravel_tag_icon.great_experience { background-image: url('icons/great_experience.svg')}
.itravel_tag_icon.group_travel { background-image: url('icons/group_travel.svg')}
.itravel_tag_icon.guided_trips { background-image: url('icons/guided_trips.svg')}
.itravel_tag_icon.hiking { background-image: url('icons/hiking.svg')}
.itravel_tag_icon.hiking_trips { background-image: url('icons/hiking_trips.svg')}
.itravel_tag_icon.honeymoon_trips { background-image: url('icons/honeymoon_trips.svg')}
.itravel_tag_icon.horse_riding { background-image: url('icons/horse_riding.svg')}
.itravel_tag_icon.hotel { background-image: url('icons/hotel.svg')}
.itravel_tag_icon.hotel_under_water { background-image: url('icons/hotel_under_water.svg')}
.itravel_tag_icon.igloos { background-image: url('icons/igloos.svg')}
.itravel_tag_icon.interaction_meetning_people { background-image: url('icons/interaction_meetning_people.svg')}
.itravel_tag_icon.island_hopping_trips { background-image: url('icons/island_hopping_trips.svg')}
.itravel_tag_icon.landscapes { background-image: url('icons/landscapes.svg')}
.itravel_tag_icon.luxury_hotels { background-image: url('icons/luxury_hotels.svg')}
.itravel_tag_icon.luxury_lodges { background-image: url('icons/luxury_lodges.svg')}
.itravel_tag_icon.luxury_specials { background-image: url('icons/luxury_specials.svg')}
.itravel_tag_icon.marriage_trips { background-image: url('icons/marriage_trips.svg')}
.itravel_tag_icon.martial_arts { background-image: url('icons/martial_arts.svg')}
.itravel_tag_icon.nature { background-image: url('icons/nature.svg')}
.itravel_tag_icon.nature_happenings { background-image: url('icons/nature_happenings.svg')}
.itravel_tag_icon.places_of_touristic_interest { background-image: url('icons/places_of_touristic_interest.svg')}
.itravel_tag_icon.premium_trips { background-image: url('icons/premium_trips.svg')}
.itravel_tag_icon.roadtrips { background-image: url('icons/roadtrips.svg')}
.itravel_tag_icon.romantic_dinner { background-image: url('icons/romantic_dinner.svg')}
.itravel_tag_icon.safari { background-image: url('icons/safari.svg')}
.itravel_tag_icon.safari_pure { background-image: url('icons/safari_pure.svg')}
.itravel_tag_icon.safari_roundtrips { background-image: url('icons/safari_roundtrips.svg')}
.itravel_tag_icon.sailing_trips { background-image: url('icons/sailing_trips.svg')}
.itravel_tag_icon.select_trips { background-image: url('icons/select_trips.svg')}
.itravel_tag_icon.shopping { background-image: url('icons/shopping.svg')}
.itravel_tag_icon.short_safari { background-image: url('icons/short_safari.svg')}
.itravel_tag_icon.sleeping_outside { background-image: url('icons/sleeping_outside.svg')}
.itravel_tag_icon.special_places_to_stay { background-image: url('icons/special_places_to_stay.svg')}
.itravel_tag_icon.special_romance { background-image: url('icons/special_romance.svg')}
.itravel_tag_icon.sport_events { background-image: url('icons/sport_events.svg')}
.itravel_tag_icon.stopover_trips { background-image: url('icons/stopover_trips.svg')}
.itravel_tag_icon.sunset { background-image: url('icons/sunset.svg')}
.itravel_tag_icon.tailored_trips { background-image: url('icons/tailored_trips.svg')}
.itravel_tag_icon.telephone_support { background-image: url('icons/telephone_support.svg')}
.itravel_tag_icon.tent_camps { background-image: url('icons/tent_camps.svg')}
.itravel_tag_icon.train_trips { background-image: url('icons/train_trips.svg')}
.itravel_tag_icon.treehouse { background-image: url('icons/treehouse.svg')}
.itravel_tag_icon.trend_sports { background-image: url('icons/trend_sports.svg')}
.itravel_tag_icon.trips_with_rental_car { background-image: url('icons/trips_with_rental_car.svg')}
.itravel_tag_icon.water_sports { background-image: url('icons/water_sports.svg')}
.itravel_tag_icon.wellness { background-image: url('icons/wellness.svg')}
.itravel_tag_icon.wintersports1 { background-image: url('icons/wintersports1.svg')}
.itravel_tag_icon.wintersports2 { background-image: url('icons/wintersports2.svg')}
.itravel_tag_icon.wintersports3 { background-image: url('icons/wintersports3.svg')}