@import "prosemirror-example-setup/style/style.css";
@import "prosemirror-gapcursor/style/gapcursor.css";
@import "prosemirror-menu/style/menu.css";
@import "prosemirror-view/style/prosemirror.css";

.ProseMirror {
  padding: $input-padding-y $input-padding-x;
  outline: none;

  &-menubar {
    // This centers the icons in the menubar by adding the top padding of the
    // outer element to its bottom padding.
    padding-bottom: calc(1px + #{$input-padding-y});
    // The prosemirror sticky implementation in JS is not that robust.
    // It's disabled in the menubar options.
    // This works in modern browsers.
    position: sticky;
  }

  // The editor renders list items with a paragraph inside.
  // We just visually remove its margin as it can't be fixed in JS in a good way.
  // The serialized markdown does not contain paragraphs.
  li > p {
    margin-bottom: 0;
  }

  // The markdown editor can contain inline images. This just makes sure that large
  // images don't grow outside the actual editor.
  img {
    max-width: 100%;
  }
}
