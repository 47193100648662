// A simple drop shadow, visible only on one side of an element.
// Only works on positioned elements.
@mixin drop-shadow($direction) {
  &::after {
    content: " ";
    position: absolute;
    background-image: linear-gradient(
      to $direction,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );

    @if $direction == top {
      height: 4px;
      top: -4px;
      left: 0;
      right: 0;
    }

    @if $direction == bottom {
      height: 4px;
      bottom: -4px;
      left: 0;
      right: 0;
    }

    @if $direction == right {
      width: 4px;
      right: -4px;
      top: 0;
      bottom: 0;
    }

    @if $direction == left {
      width: 4px;
      left: -4px;
      top: 0;
      bottom: 0;
    }
  }
}
